import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import getOr from 'lodash/fp/getOr';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import pipe from 'lodash/fp/pipe';
import format from 'date-fns/fp/format';
import parseISO from 'date-fns/fp/parseISO';
import Layout from '../../organisms/Layout/Layout';
import PageWrapper from '../../organisms/PageWrapper/PageWrapper';
import { breakpoints, unitConverter as uc } from '../../../styles/base';
import usePagination from '../../../hooks/usePagination';
import useSanityFetchDocuments from '../../../hooks/useSanityFetchDocuments';
import client from '../../../utils/sanityClient';
import { getSanityDocumentsPage } from '../../../utils/sanityUtils';
import NewsCoverageTile from '../../molecules/NewsCoverageTile/NewsCoverageTile';
import Pagination from '../../organisms/Pagination/Pagination';
import { Heading2, Heading3 } from '../../atoms/Headings/Headings';
import NewsCoverageTileSkeleton from '../../molecules/NewsCoverageTileSkeleton/NewsCoverageTileSkeleton';
import useLocalize from '../../../hooks/useLocalize';

const containerCss = css`
  margin: ${uc('150px 100px 50px')};

  @media (${breakpoints.tablet}) {
    margin-top: ${uc('180px')};
    margin-bottom: ${uc('60px')};
  }
`;

const articlesCss = css`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: ${uc('16px')};
`;

const SkeletonTiles = ({ times = 0 }) =>
  Array(...Array(times)).map(() => (
    <NewsCoverageTileSkeleton key={`${Math.random()}-key`} />
  ));

const NewsCoveragesPage = ({
  data: rawData,
  location,
  pageContext: { locale },
}) => {
  if (!rawData) {
    return null;
  }
  const data = useLocalize(rawData, locale);

  const { page, perPage, setPage } = usePagination(12, 1);
  const sanityPage = getSanityDocumentsPage(page, perPage);
  const baseQuery = `*[_type=="newsCoverage" 
      && !(_id in path("drafts.*"))
      && enabledLocale == "${locale}"]`;
  const query = `//groq 
  {
    "records": ${baseQuery} | order(postDate desc) ${sanityPage}, 
    "totalRecords": count(${baseQuery})
  }`;
  const { loading, resolved, ...result } = useSanityFetchDocuments(
    client,
    query
  );

  const newsCoverages = getOr([], 'data.records', result);
  const totalPages = Math.floor(
    getOr(1, 'data.totalRecords', result) / perPage
  );
  const shouldShowNewsCoverages = resolved && !isEmpty(newsCoverages);
  const notFoundNewsCoverages = resolved && isEmpty(newsCoverages);

  return (
    <Layout
      locale={locale}
      sanityId={data.sanityNewsCoveragesPage._id}
      {...data.sanityNewsCoveragesPage.route}
      href={location.href}
    >
      <PageWrapper>
        <div css={containerCss}>
          <div css={articlesCss}>
            <Heading2>{data.sanityNewsCoveragesPage.title}</Heading2>
          </div>
          <div css={articlesCss}>
            {loading && <SkeletonTiles times={12} />}
            {notFoundNewsCoverages && <Heading3>Not Found</Heading3>}

            {shouldShowNewsCoverages &&
              map(
                ({ _id, link, logo, postDate, title }) => (
                  <NewsCoverageTile
                    key={_id}
                    title={title}
                    subtitle={pipe(parseISO, format('MMMM dd, yyyy'))(postDate)}
                    link={link}
                    image={logo}
                  />
                ),
                newsCoverages
              )}
          </div>

          {shouldShowNewsCoverages && (
            <div css={articlesCss}>
              <Pagination
                totalPages={totalPages}
                onPageChange={_page => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                  setPage(_page);
                }}
              />
            </div>
          )}
        </div>
      </PageWrapper>
    </Layout>
  );
};

NewsCoveragesPage.propTypes = {
  data: PropTypes.shape({ sanityNewsCoveragesPage: {} }),
  location: PropTypes.shape({ href: PropTypes.string }),
  pageContext: PropTypes.shape({ locale: PropTypes.string }),
};

NewsCoveragesPage.defaultProps = {
  data: {},
  location: { href: '' },
  pageContext: { locale: '' },
};

export const query = graphql`
  query($slug: String) {
    sanityNewsCoveragesPage(route: { slug: { current: { eq: $slug } } }) {
      _id
      title: _rawTitle(resolveReferences: { maxDepth: 100 })
      route: _rawRoute(resolveReferences: { maxDepth: 100 })
    }
  }
`;

export default NewsCoveragesPage;
